export const login = '/smart-loan/session'; // 登陆
export const validationCode = '/smart-loan/app/validation/code'; // 获取验证码
export const getUserInfo = '/smart-loan/user/getUserInfo'; // 获取用户信息
export const contractDetail = '/smart-loan/contract/contractDetail'; // 查看合同详情
export const getProduct = '/smart-loan/product/getProduct'; // 获取产品
export const scheduleCalc = '/smart-loan/schedule/scheduleCalc'; // 获取试算
export const applyFast = '/smart-loan/apply/fast'; // 快捷申请
export const contactUs = '/smart-loan/session/contactUs'; // 联系我们

export default {
  login,
  validationCode,
  getUserInfo,
  contractDetail,
  getProduct,
  scheduleCalc,
};
